// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-jsx": () => import("/Users/kazuhirohashimoto/Documents/GitHub/zypsy-design/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-meta-tag-jsx": () => import("/Users/kazuhirohashimoto/Documents/GitHub/zypsy-design/src/pages/MetaTag.jsx" /* webpackChunkName: "component---src-pages-meta-tag-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/kazuhirohashimoto/Documents/GitHub/zypsy-design/.cache/data.json")

